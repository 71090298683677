.progress-bar {
  position: relative;
  height: 56px;
}

.progress-bar .step {
  background-color: #e3e3e3;
  position: absolute;
  top: 26.79%;
  bottom: 28.57%;
  height: 25px;
}

.progress-bar .step-gradient-1 {
  background: linear-gradient(90deg, #f28f78 0%, #f49d70 100%);
}

.progress-bar .step-gradient-2 {
  background: linear-gradient(90deg, #f49e70 0%, #f5ac67 100%);
}

.progress-bar .step-gradient-3 {
  background: linear-gradient(90deg, #f5ac67 0%, #f6bb5e 100%);
}

.progress-bar .step-gradient-4 {
  background: linear-gradient(90deg, #f7bb5e 0%, #f2c155 100%);
}

.progress-bar .step-first {
  border-radius: 100px 0px 0px 100px;
}

.progress-bar .watch-face {
  position: absolute;
  right: 0%;
  top: 0%;
  width: 56px;
  height: 56px;
  background-color: #ffd472;
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 100px 100px 100px 100px;
}

.progress-bar .watch-face img {
  position: relative;
  left: 16px;
  top: 8px;
}
