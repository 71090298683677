.Toastify__toast-container {
  padding: 0 1rem;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100%;
  }
}

@media only screen and (min-width: 480px) {
  .Toastify__toast-container {
    width: 480px;
  }
}

.Toastify__toast-container--bottom-center {
  bottom: calc(env(safe-area-inset-bottom, 0) + 10px);
}

.Toastify__toast {
  color: #262261;
  background-color: #f8fff9;
  border-radius: 24px;
  min-height: 50px;
  padding: 0 8px;
}

.Toastify__toast-icon {
  width: 30px;
  height: 30px;
}

.Toastify__close-button {
  display: none;
}
