.order-garmin img {
  display: block;
  margin: 2em auto;
}

.order-garmin hr {
  color: #c6c6c6;
}

.order-garmin a.btn-primary {
  color: #fff;
  text-align: center;
  padding: 6px 18px;
  background: #262262;
  border-radius: 24px;
  display: inline-block;
  border: none;
  cursor: pointer;
}
