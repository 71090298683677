body:has(.study-resources) {
  --primary-color: #262262;
  --btn-color: white;
  --bg-color: white;
  --cover-bg-color: #eff4fd;
  --link-color: #0c8cdb;
  --border-color: #ececec;
  --transition-delay: .2s; 
}

body:has(.study-resources) {
  background-color: var(--bg-color);
}

body:has(.study-resources) .mdhui-navigation-bar {
  background-color: var(--bg-color);
  padding-left: 1rem !important;
}

#root:has(.study-resources) {
  padding: 0;
}

#root .study-resources h1 {
  line-height: 1.4em;
  margin: 1rem 0;
  text-align: left;
}

#root .study-resources h2 {
  color: var(--primary-color);
  margin: 1rem 0;
  line-height: 1.4em;
  text-align: left;
}

#root .study-resources h3 {
  color: var(--primary-color);
  margin: 1rem 0;
  text-align: left;
}

#root .study-resources h4 {
  margin: 0;
}

#root .study-resources h5 {
  margin: 0;
  color: var(--primary-color);
}

#root .study-resources .header {
  padding: 0 1rem 1rem;
}

#root .study-resources .section-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  padding: 1rem;
}

#root .study-resources .section-header div:first-child {
  flex: 1 1 auto;
}

#root .study-resources .section-header div:last-child {
  flex: 0 0 1rem;
}

#root .study-resources .section-body {
  padding-left: 2rem;
  padding-right: 1rem;
}

#root .study-resources .section-body.expanded {
  max-height: 100%;
  transition: all var(--transition-delay) ease-in-out;
}

#root .study-resources .section-body.collapsed {
  max-height: 0;
  overflow: hidden;
  transition: all var(--transition-delay) ease-in-out;
}

#root .study-resources .expand-container {
  overflow: hidden;
}

#root .study-resources section {
  border-bottom: 1px solid var(--border-color);
  transition: background-color var(--transition-delay);
}

#root .study-resources section:first-of-type {
  border-top: 1px solid var(--border-color);
}

#root .study-resources section.collapsed {
  background-color: var(--bg-color);
}

#root .study-resources section.expanded {
  background-color: #f5f6f8;
}

#root .study-resources section label {
  color: var(--primary-color);
  font-weight: bold;
}

#root .study-resources section p {
  padding: 0;
  margin: 1rem 0;
}

#root .study-resources section .item {
  display: flex;
  gap: 1rem;
  align-items: flex-start;
  padding: 1rem 0;
  border-bottom: 1px solid var(--border-color);
}

#root .study-resources section .item:last-child {
  border: none;
}

#root .study-resources section .item > *:first-child {
  flex: 0 0 50px;
}

#root .study-resources section .grid {
  display: grid;
  grid-template-columns: 30% 1fr;
  grid-template-rows: repeat(2, auto);
  column-gap: 2rem;
  align-items: center;
}

#root .study-resources section .grid img {
  width: 100px;
}

#root .long-covid-summary section img {
  width: 60%;
  display: block;
  margin: 1rem auto;
  max-width: 200px;
}

#root .study-resources section .button-row {
  display: flex;
  gap: 1rem;
  margin-top: 1em;
}

#root .study-resources a {
  color: var(--link-color) ;
  text-decoration: underline;
}

#root .study-resources strong {
  color: var(--link-color);
  font-weight: bold;
}

#root .study-resources .btn-primary {
  line-height: 2.35em;
  color: var(--btn-color);
  padding-left: 24px;
  padding-right: 24px;
  background: var(--primary-color);
  border-radius: 24px;
  display: inline-block;
  font-size: 0.88em;
  font-weight: bold;
  border: none;
  cursor: pointer;
  text-align: left !important;
}

#root .study-resources a.btn-primary {
  color: var(--btn-color) !important;
  text-decoration: none !important;
}

#root .study-resources .btn-secondary {
  line-height: 2.35em;
  color: var(--primary-color);
  transition: var(--transition-delay) ease-in-out;
  padding-left: 24px;
  padding-right: 24px;
  background: white;
  border-radius: 24px;
  display: inline-block;
  font-size: 0.88em;
  font-weight: bold;
  border: 1px solid var(--primary-color);
  cursor: pointer;
  text-align: left !important;
}

#root .study-resources a.btn-secondary {
  color: var(--primary-color) !important;
  text-decoration: none !important;
}

#root .study-resources .btn-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: var(--primary-color);
  text-decoration: none;
  cursor: pointer;
  margin-top: 0.1em;
  font-size: 1.1em;
}

.pacing {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.pacing *:first-child {
  flex: 0 0 50px;
}

.pacing *:last-child {
  flex: 1 1 auto;
}

.carousel-item-container {
  padding-left: 1rem;
  padding-right: 1rem;
}

.carousel-item-container ul li {
  list-style-type: disc;
}

.carousel-item-container ul.symptoms {
  color: var(--primary-color);
  font-weight: bold;
}

.carousel-item-container ul.symptoms li {
  list-style-position: outside;
  padding-left: 1em;
}

.carousel-item-container ol {
  list-style: none;
  counter-reset: item;
  padding-left: 0;
}

.carousel-item-container ol > li {
  counter-increment: item;
  margin: 2rem 0;
}

.carousel-item-container ol > li:before {
  content: "0" counter(item) " ";
  font-size: 3.5em;
  font-weight: bold;
}

.slide {
  text-align: left !important;
}

.dot {
  background: #979797 !important;
  opacity: 1 !important;
}

.dot.selected {
  background: var(--primary-color) !important;
}

.control-dots {
  position: fixed !important;
  left: 0 !important;
  bottom: env(safe-area-inset-bottom, 0) !important;
}

body:has(.study-resources .selected .cover) {
  background-color: var(--cover-bg-color);
}

body:has(.study-resources .selected .cover) .mdhui-navigation-bar {
  background-color: var(--cover-bg-color) !important;
}

.pots-grid {
  width: 325px;
  height: 350px;
  display: grid;
  grid-template-columns: 160px 160px;
  grid-template-rows: repeat(2, auto);
  grid-row: auto auto;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  margin: 2.5rem auto;
}

.pots-grid > div {
  border-radius: 8px;
  border: 5px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  line-height: 1.2em;
  padding: 0 1em;
}

.pots-grid > div:nth-child(1),
.pots-grid > div:nth-child(4) {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90deg, #86de9a 0%, #73b4d1 100%);
}

.pots-grid > div:nth-child(2),
.pots-grid > div:nth-child(3) {
  background: linear-gradient(white, white) padding-box,
    linear-gradient(90deg, #f28f78 0%, #f7c656 100%);
}

.plus-minus {
  position: relative;
	width: 1rem;
	height: 1rem;
	cursor: pointer;
}

.plus-minus:before, .plus-minus:after {
  content: "";
  display: block;
  background-color: var(--primary-color);
  position: absolute;		
  top: 50%; left: 0;
  transition: var(--transition-delay) ease-in-out;
  width: 100%;
  height: 3px;
}

.plus-minus.expanded:before {
	transform: translatey(-50%) rotate(-90deg);
	opacity: 0;
}

.plus-minus.expanded:after {
	transform: translatey(-50%) rotate(0);
}

.plus-minus.collapsed:before {
	transform: translatey(-50%);
}

.plus-minus.collapsed:after {
	transform: translatey(-50%) rotate(90deg);
}