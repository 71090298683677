* {
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  line-height: 1.5em;
}

@supports (font: -apple-system-body) {
  html {
    font: -apple-system-body;
  }
}

body {
  padding-top: env(safe-area-inset-top, 0);
  padding-bottom: env(safe-area-inset-bottom, 0);
}

body:has(.card-list) {
  background-color: #f6f6f6;
}

body:has(.card-list) .mdhui-navigation-bar {
  background-color: #f6f6f6 !important;
  padding-left: 0 !important;
}

#root {
  max-width: 632px;
  margin: 0 auto;
  padding: 1rem;
}

.card-list {
  position: relative;
  background: #f6f6f6;
}

.card-item {
  margin-top: 1.1rem;
  margin-bottom: 1.1rem;
  position: relative;
  border-radius: 24px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.16);
  background: white;
  color: #262262;
  cursor: pointer;
}

.enroll .card-item:only-of-type {
  box-shadow: 0px 1px 10px #ffd472;
}

.card-item.card-item-locked {
  opacity: 50%;
  cursor: not-allowed;
}

.card-item.action-button {
  cursor: pointer;
  height: 59px;
  border-radius: 12px;
  box-shadow: 0 4px 16px 0 rgba(69, 91, 99, 0.08);
}
.card-item.action-button .card-body {
  padding-left: 1.1rem;
}
.card-item.action-button .card-title {
  padding: 0px;
}
.card-item .card-title {
  font-size: 1em;
  font-weight: bold;
  padding: 1.1rem 1.1rem 0 1.1rem;
}
.card-item .card-body {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.card-item .card-body .card-text {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.card-item .card-body .card-text .card-body-description {
  flex: 0 0 auto;
}
.card-item .card-body .card-body-icon {
  padding: 1.1rem;
  padding-left: 0;
  max-width: 100px;
  min-width: 50px;
}
.card-item .card-body .card-body-icon img {
  width: 100%;
}
.card-item .card-body .card-body-icon.lock-icon img {
  height: 50px;
  width: 50px;
}
.card-item .card-body .card-toggle-expand {
  padding: 21px;
}
.card-item p {
  font-size: 0.88em;
  margin: 1.2rem;
}

.card-item.Completed .card-footer {
  border-top: none;
  padding: 0px;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
  min-height: 0px;
}

.card-item.Completed .card-title {
  padding: 0px;
  flex: 1 1 auto;
}

.card-item .card-footer {
  border-top: 1px solid #f6f6f6;
  display: flex;
  flex-direction: row;
  min-height: 32px;
  align-items: center;
  padding: 8px 19px 8px 19px;
  font-size: 0.8em;
}

.card-item .card-footer .cta-button {
  flex: 1 1 auto;
}
.card-item .card-footer .time-to-complete {
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}
.card-item .card-footer .time-to-complete-icon {
  height: 16px;
  position: relative;
  top: -1px;
  padding-right: 16px;
}
.card-item .card-footer .card-class {
  text-transform: uppercase;
  flex: 1 1 auto;
  padding-right: 10px;
}
.card-item .card-footer .card-description {
  flex: 1 1 auto;
  text-align: right;
}
.card-item .card-footer .date-completed {
  color: #555;
}

.card-item .card-footer .card-description .card-action {
  color: #262262;
  border-radius: 8px;
  font-weight: bold;
  padding: 8px 16px 8px 16px;
  border: none;
  cursor: pointer;
  font-size: 0.75em;
  font-weight: bold;
  background: linear-gradient(to right, #f38d7a 0%, #f8c954 100%);
  text-transform: uppercase;
  margin: 0px;
}
.card-item.Completed .card-footer .card-description .card-action {
  background: #ffffff;
  font-size: 1em;
  margin-top: 8px;
  padding: 16px;
}
.card-item .card-action {
  color: #262262;
  border-radius: 24px;
  text-decoration: none;
  font-weight: bold;
  padding: 16px 32px 16px 32px;
  border: none;
  cursor: pointer;
  font-size: 1em;
  background: #ffffff;
}
.card .card-item.card-item-active {
  cursor: pointer;
}
.card .card-item.Blocked {
  opacity: 0.5;
}
.card .card-item.NotStarted,
.card .card-item.Started {
  cursor: pointer;
}

a {
  color: #000000;
}

h1 {
  padding: 0;
  text-align: left;
}

h2 {
  color: #262262;
  font-size: 1.5em;
}

h3 {
  color: #262262;
  margin: 0;
  font-size: 1.3em;
}

h4 {
  color: #262262;
  margin: 0;
  font-size: 1em;
}

img {
  max-width: 100%;
  height: auto;
}

.center {
  text-align: center;
}

.small {
  font-size: 0.82em;
}

p {
  font-size: 1em;
  padding: 0;
}

hr {
  border: none;
  border-top: 1px solid #000000;
  margin: 1em 0;
}

ul {
  margin: 1em 0;
  padding-left: 2em;
}

.loading-indicator {
  padding: 1em;
  color: #bbb;
  text-align: center;
}

/* HACKHACKHACK: Use mdhui CSS variables later.*/

.mdhui-navigation-bar {
  padding-top: 1rem !important;
  opacity: 0.9;
}

  .mdhui-navigation-bar.scroll-shadow {
    box-shadow: 0px 0px 5px 0px #aaa !important;
  }

  .mdhui-navigation-bar .back-button {
    color: #216fb4 !important;
    position: static !important;
  }

    .mdhui-navigation-bar .back-button svg {
      margin-right: 0.5rem;
    }